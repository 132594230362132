import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const ContactPageHeroSection = () => {
  const headingRef = useRef(null);
  const paragraphRef = useRef(null);
  const buttonRef = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      headingRef.current,
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out', delay: 0.5 }
    );

    gsap.fromTo(
      paragraphRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out', delay: 1 }
    );

    gsap.fromTo(
      buttonRef.current,
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 1, ease: 'power2.out', delay: 1.5 }
    );

    gsap.fromTo(
      phoneRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out', delay: 2 }
    );
  }, []);

  return (
    <section className="text-white py-20 min-h-screen flex flex-col items-center justify-center font-mono bg-blue-500 bg-opacity-5">
      <div className="text-center max-w-lg px-6">
        <h1 ref={headingRef} className="text-5xl mb-4 font-bold">
          Let's Connect!
        </h1>
        <p ref={paragraphRef} className="text-2xl mb-8">
          I'd love to hear from you, so feel free to reach out to me.
        </p>
        <div className="flex flex-col md:flex-row justify-center gap-6 mt-8">
          <a 
            ref={buttonRef}
            href="mailto:mattias.orahem@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="opacity-0 bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            Email Me!
          </a>
        </div>
      </div>
      <div ref={phoneRef} className='flex flex-col items-center justify-center'>
        <p className="mt-10 font-sans text-lg font-semibold">
          I'm also available to take your call
        </p>
        <a 
          href="tel:+46739458237" 
          target="_blank"
          rel='noreferrer'
          className="text-lg font-bold text-blue-500 hover:text-white underline"
        >
          +46 73 945 82 37
        </a> 
      </div>
    </section>
  );
};

export default ContactPageHeroSection;
