import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import webimg from '../../images/webdesighn.webp';
import meimg from '../../images/me.webp';
import archimg from '../../images/arch.webp';

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
  const headlineRef = useRef(null);
  const subtextRef = useRef(null);
  const nameRef = useRef(null);
  const spineRef = useRef(null);

  const sectionWhatIDoRef = useRef(null);
  const sectionMyStartRef = useRef(null);
  const sectionTechStackRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: 'power2.out' } });
  
    const refs = [headlineRef, nameRef, subtextRef];
  
    refs.forEach((ref, index) => {
      tl.fromTo(
        ref.current.querySelector('.overlay'),
        { x: '-100%' },
        { x: '100%', duration: 1.5, backgroundColor: '#3b82f6', delay: index * 0.2 },
        'start'
      ).fromTo(
        ref.current.querySelector('.text'),
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.8 },
        '-=1.2'
      );
    });
  
    tl.fromTo(
      spineRef.current,
      { height: '0%' },
      { height: '80%', duration: 3 },
      'start+=2'
    );
  
    const sections = [sectionWhatIDoRef, sectionMyStartRef, sectionTechStackRef];
    sections.forEach((section) => {
      gsap.fromTo(
        section.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section.current,
            start: 'top 80%',
            toggleActions: 'play none none reverse',
          },
        }
      );
    });
  
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <section className="relative text-gray-200 font-mono py-20 min-h-screen flex flex-col bg-blue-500 bg-opacity-5">
      <div
        ref={spineRef}
        className="spine absolute left-[2%] top-[5%] sm:top-[10%] xs:top-[5%] w-1 bg-gradient-to-b from-blue-600 via-blue-500 to-blue-200 shadow-lg rounded-full sm:left-[5%] xs:h-[80%] sm:h-[60%] lg:h-[50%] lg:left-[10%] lg:opacity-100"
      ></div>

      <div className="relative h-[50vh] flex justify-center items-center pt-[25%] pb-[10%] ">
        <div className="absolute text-center">
          <h2 ref={headlineRef} className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4 relative overflow-hidden">
            <span className="overlay absolute inset-0 bg-blue-500 z-10"></span>
            <span className="text relative z-0">
              orahem<span className="text-blue-500">.com</span>
            </span>
          </h2>
          <p ref={nameRef} className="text-xl md:text-2xl text-blue-500 mb-4 relative overflow-hidden">
            <span className="overlay absolute inset-0 bg-blue-500 z-10"></span>
            <span className="text relative z-0">Mattias Orahem</span>
          </p>
          <p ref={subtextRef} className="text-lg md:text-xl font-bold relative overflow-hidden">
            <span className="overlay absolute inset-0 bg-blue-500 z-10"></span>
            <span className="text relative z-0">Passionate frontend developer</span>
          </p>
        </div>
      </div>

      <div className="container mx-auto text-white font-mono px-6 lg:px-20 mt-[30vh]">
        <div className="flex flex-col lg:flex-row items-center opacity-0" ref={sectionWhatIDoRef}>
          <div className="w-full lg:w-1/2 p-4">
            <h2 className="text-2xl md:text-3xl font-bold mb-3">&lt;what i do&gt;</h2>
            <p className="text-base md:text-lg">
              I craft websites that are visually appealing, snappy, and intuitive. My goal is to deliver unique designs that engage users and provide seamless interactions. I build eye-catching user interfaces while ensuring a smooth experience for every visitor.
            </p>
          </div>
          <div className="w-full lg:w-1/2 p-4">
            <img
              src={webimg}
              alt="Summery"
              loading="lazy"
              className="w-auto h-auto mx-auto rounded-3xl"
            />
          </div>
        </div>

        <div className="opacity-0 mt-[40vh] mb-[20rem]" ref={sectionMyStartRef}>
          <div className="flex flex-col md:flex-row items-center -mb-[2rem]">
            <div className="w-full md:w-1/2 p-4">
              <h2 className="text-2xl md:text-3xl mb-3 font-bold">&lt;jurney&gt;</h2>
              <p className="text-base md:text-lg">
                My journey began during my first year of high school with a course on game development using Unity. It was there that I discovered my passion for programming and decided it would be my lifelong pursuit.
                <br /><br />
                I excelled in programming, starting with AI and data science projects, messing around with Linux servers, as well as embedded programming in C++. Later, I delved into web development and web server programming, where I realized my true passion for frontend development.
              </p>
            </div>
            <div className="w-full md:w-1/2 p-4">
              <img
                src={archimg}
                alt="First time installing Arch Linux on my laptop"
                loading="lazy"
                className="w-[600px] h-[400px] object-cover rounded-3xl shadow-lg"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center mt-8">
            <div className="w-full md:w-1/2 order-1 md:order-2 mr-0 md:mr-[10rem] p-4">
              <p className="text-base md:text-lg">
                Now, I'm an aspiring front-end developer who loves designing, programming, and maintaining websites. My passion is boundless, and my enthusiasm for creating exceptional web experiences grows every day.
                <br /> <br />
                I dive into the details to craft designs that are both visually stunning and intuitive. It feels like my calling to make things look amazing while ensuring they function flawlessly.
                <br /> <br />
                Keeping up with the latest tech trends and innovations is my favorite pastime, from new computer components to cutting-edge software. I'm always eager to learn and experiment with the latest and greatest.
              </p>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-1 p-4">
              <img
                src={meimg}
                alt="my desktop"
                loading="lazy"
                className="w-[600px] h-[400px] object-cover rounded-3xl shadow-lg"
              />
            </div>
          </div>
        </div>

        <div className="opacity-0 mb-[5vh]" ref={sectionTechStackRef}>
          <div className="w-full p-4">
            <h3 className="text-2xl md:text-3xl font-semibold mb-3">&lt;tech stack&gt;</h3>
            <p className="text-lg font-bold pb-4">This website was created from the ground up with:</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 text-base">
              <p>JavaScript, Tailwind CSS, HTML, React</p>
              <p>Photoshop, Illustrator</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;